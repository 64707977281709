/**
 * Handles form submissions via Ajax using jQuery Form Plugin (https://jquery-form.github.io/form/).
 * Expects that the forms are using Bootstrap 4 layout.
 *
 * @param {jQuery} $form
 * @param {jQuery} $successContainer
 */
function handleFormSubmission($form, $successContainer) {
    if (!$form.length) return;

    let $submitButton = $form.find('input[name=submit]');
    let submitButtonInitialText = $submitButton.val();

    $form.submit(function(event) {
        event.preventDefault();

        grecaptcha.ready(function() {
            grecaptcha.execute('6LeB-EUqAAAAABTY-wow-GrCX2DUJChXb0k4fb-u', {action: 'submit'}).then(function(token) {
                // Add the "g-recaptcha-response" field with the token to the form data.
                let $gRecaptchaResponse = $('<input type="hidden" name="g-recaptcha-response">').val(token);
                $form.append($gRecaptchaResponse);
                $form.ajaxSubmit({
                    success: success,
                    beforeSubmit: function(arr, $form) {
                        let sendingText = '...';
                        $form.find('.btn').prop('disabled', true).text(sendingText).val(sendingText);
                    }
                });
            });
        });
    });
    /**
     * @param {object} response
     * @param {boolean} response.result
     * @param {object} response.validationErrors Field name => error message.
     * @param {string} statusText
     * @param {object} xhr
     * @param {object} $form
     */
    function success(response, statusText, xhr, $form) {
        $form.find('.btn').prop('disabled', false).text(submitButtonInitialText).val(submitButtonInitialText);
        
        const responseJson = JSON.parse(response);

        if (responseJson.errors) {
            console.log(responseJson.errors);
            $form.addClass('was-validated');
            $form.find('.invalid-feedback').remove();
            $form.find('.is-invalid').removeClass('is-invalid');

            for (let field in responseJson.errors) {
                
                let errorMessage = responseJson.errors[field][0];
               
                let $inputElement = $form.find('[name="'+field+'"]');
                if (!$inputElement.length) {
                    $inputElement = $form.find('[name="'+field+'[]"]').first();
                    if (!$inputElement.length) continue;
                }
                $inputElement.addClass('is-invalid');
                $inputElement[0].setCustomValidity(errorMessage);

                let $formGroup = $inputElement.closest('.form-group');
                $formGroup.append('<div class="invalid-feedback">'+errorMessage+'</div>');
                $inputElement.on('click', function() {
                    $inputElement.removeClass('is-invalid');
                    this.setCustomValidity('');
                    $formGroup.find('.invalid-feedback').remove();
                })
            }
        }
        else if (responseJson.success == true) {
            // In some cases you would like to reuse a form again, so I'm hiding and resetting it instead of removing.
            $form.hide();
            $form[0].reset();
            $successContainer.fadeIn();

            dataLayer.push({ 'event': 'formSubmission', 'formName': $form[0].id });
            dataLayer.push({
                'event': 'formTracking',
                'category': 'form',
                'action': 'submit',
                'label': '$form[0].id'
            });

            if (typeof fathom !== 'undefined') {
                // Catch all tracking
                window.fathom.setSite(fathomSiteIds.catchAll)
                fathom.trackEvent($form[0].id);

                // Individual site tracking
                window.fathom.setSite(fathomSiteIds[currentLanguageId])
                fathom.trackEvent($form[0].id);
            }
        }
    }
}

document.addEventListener('DOMContentLoaded', function() {
    let formsIds = [
        'ri', // Request Information form
        'ae', // Ask the Expert big form
        'aoe', // Ask our Experts popup form
        'so', // Sales Office form
        'rq' // Request a Quote form
    ];
    const formIdPostfix = '-form';
    const successContainerPostfix = '-success-submission';

    for (let formId of formsIds) {
        handleFormSubmission($('#' + formId + formIdPostfix), $('#' + formId + successContainerPostfix));
    }
});


var formIds = $("#aoe-form, #rq-form, #ae-form, #ri-form");

$("[data-dismiss=modal]").on('click', function(){
    var modalContent = $(this).parent().parent();
    setTimeout(function(){
      formIds.removeClass('was-validated');
      formIds.find('input, textarea, select').removeClass('is-invalid');
      formIds.find('.btn').prop('disabled', false);
      modalContent.find('form').show();
      modalContent.find('.form-success-submission').hide();
      modalContent.find("#frm-state")
        .attr('required','')
        .parent()
        .show();
    },1000);
});



formIds && formIds.find("#frm-country").on('change', function(){
    var formParent = $(this).parent().parent();
    if($(this).val() == "US" || $(this).val() == "United States") {
        formParent.find("#frm-state")
            .attr('required','')
            .parent()
            .show();
        if(formParent.parent().attr('id') == "ae-form" || formParent.parent().attr('id') == "ri-form") {
            formParent
                .find("#frm-zip, #frm-country")
                .parent()
                .attr('class','form-group col-sm-6 col-md-4');
        }
    } else {
        formParent.find("#frm-state")
            .removeAttr('required')    
            .parent()
            .hide()
            .find('input')
            .removeAttr('required')
            .focus()
            .val('')
            .blur();
            if(formParent.parent().attr('id') == "ae-form" || formParent.parent().attr('id') == "ri-form") {
                formParent
                    .find("#frm-zip, #frm-country")
                    .parent()
                    .attr('class','form-group col-sm-6 col-md');
            }
    }
});

formIds && formIds
    .attr('novalidate', 'novalidate')
    .find("#frm-country").trigger('change');

// Add document title to specific forms
$("#aoe-form, #rq-form, #ae-form, #ri-form").find('input[name="rct_prod"]').val(document.title);