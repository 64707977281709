/**
 * Wistia Lazy is a script for lazy-loading Wistia videos while you scroll the page.
 *
 * Usage:
 *      Add a container on the page, which will be replaced with a Wistia Embed code when it gets close to the viewport:
 *      <div data-wistia-lazy-id="1e9morcly8" data-wistia-lazy-embed="inline" data-wistia-lazy-still="..."></div>
 *
 *      The "data-wistia-lazy-embed-type" parameter can be either "inline" or "popover".
 *      The "data-wistia-lazy-still" expects a URL to the still image that will be used instead of the default thumbnail.
 *
 * @author Pavlo Tkachov
 * 2022
 */

(function () {
    let mainScriptLoaded = false

    /**
     * Popover: Responsive Thumbnail option
     * Inline: Responsive Standard option
     */
    const embedTypes = {
        popover: `
            <span class="wistia_embed wistia_async_[wistiaId] popover=true popoverAnimateThumbnail=true videoFoam=true" style="display:inline-block;height:100%;position:relative;width:100%">&nbsp;</span>
        `,
        // "wistia_swatch" element is used for the videos with moving thumbnails only,
        // but doesn't cause issues with regular embeds either.
        inline: `
            <div class="wistia_embed wistia_async_[wistiaId] seo=false videoFoam=true [still]" style="height:100%;position:relative;width:100%">
                <div class="wistia_swatch" style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;">
                    <img src="https://fast.wistia.com/embed/medias/[wistiaId]/swatch" style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;"/>
                </div>
            </div>
        `
    }


    const videoContainers = document.querySelectorAll('[data-wistia-lazy-id]')
    const options = {
        root: null, // Viewport
        rootMargin: '25%',
        threshold: 0
    }
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const element = entry.target
                const wistiaId = element.dataset.wistiaLazyId
                const embed = prepareEmbed(element.dataset.wistiaLazyEmbed, wistiaId, element.dataset.wistiaLazyStill)

                loadMainScript()
                loadVideoMedia(wistiaId)
                element.replaceWith(embed)
                observer.unobserve(entry.target)
            }
        })
    }, options)

    videoContainers.forEach(element => observer.observe(element))


    /**
     * Prepares the Wistia Embed code by inserting passed wistiaId in it
     * and creating a valid element from the HTML code.
     *
     * @todo Replace "[still]" with empty string to remove it in the embeds that don't have a still image.
     *
     * @param {string} embedType
     * @param {string} wistiaId
     * @param {string} stillImage
     *
     * @returns {ChildNode}
     */
    function prepareEmbed (embedType, wistiaId, stillImage) {
        // Using regular .replace() method to make the code compatible with Safari v13.0, because Chris from Silverson
        // uses this version at this time (Feb 2022). .replaceAll() got available in Safari since v13.1.
        //
        // Modern code:
        // let embed = embedTypes[embedType].replaceAll('[wistiaId]', wistiaId)
        let embed = embedTypes[embedType].replace(/\[wistiaId\]/g, wistiaId)

        if (stillImage) embed = embed.replaceAll('[still]', `stillUrl=${stillImage}`)
        embed = embed.trim()

        const template = document.createElement('template')
        template.innerHTML = embed

        return template.content.firstChild
    }

    function loadMainScript () {
        if (!mainScriptLoaded) {
            loadScript('https://fast.wistia.com/assets/external/E-v1.js')
            mainScriptLoaded = true
        }
    }

    /**
     * @param {string} wistiaId
     */
    function loadVideoMedia (wistiaId) {
        loadScript(`https://fast.wistia.com/embed/medias/${wistiaId}.jsonp`)
    }

    /**
     * @param {string} url
     */
    function loadScript (url) {
        const scriptTag = document.createElement('script')
        scriptTag.src = url
        document.head.append(scriptTag)
    }
})();
