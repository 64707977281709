const body = document.getElementsByTagName('body')[0];

/**
 * Popups.
 */
(function() {
    const megaSidebarToggler = document.getElementById('toggle-mega-sidebar');
    const bodyOverlay = document.getElementById('body-overlay');
    let isBodyOverlayVisible = false;
    let openedPopups = [];

    let popupTogglers = document.querySelectorAll('[data-toggle-id]');
    for (let toggler of popupTogglers) {
        toggler.addEventListener('click', function(event) {
            event.preventDefault();
            togglePopup(document.getElementById(toggler.dataset.toggleId));
        });
    }

    let popupCloseButtons = document.querySelectorAll('.popup > .close');
    for (let closeButton of popupCloseButtons) {
        closeButton.addEventListener('click', function(event) {
            event.preventDefault();
            togglePopup(closeButton.parentElement);
        });
    }

    bodyOverlay.addEventListener('click', closeOpenedPopup);
    document.addEventListener('keyup', function(event) {
        /* ESC key */
        if (event.keyCode === 27) { closeOpenedPopup(); }
    });


    /**
     * Closes last opened popup.
     */
    function closeOpenedPopup() {
        if (openedPopups.length) {
            togglePopup(openedPopups[0]);
        }
    }

    /**
     * @param {HTMLElement} megaSidebar
     */
    function toggleMegaSidebar(megaSidebar) {
        megaSidebarToggler.classList.toggle('is-active');
        megaSidebar.classList.toggle('show');
    }

    /**
     * Toggles all kinds of popups and dropdowns.
     *
     * @param {HTMLElement} popup
     */
    function togglePopup(popup) {
        if (popup.id === 'mega-sidebar') {
            toggleMegaSidebar(popup);
        }
        else if (popup.classList.contains('dropdown-menu')) {
            $(popup).slideToggle(100);
        }
        else {
            let callback = function () {}
            if (popup.id === 'popup-search') {
                callback = function () { popup.querySelector('input[name="addsearch"]').focus() }
            }

            $(popup).fadeToggle(150, callback);
        }

        popupStateChanged(popup);
    }

    /**
     * Keeps track of opened popups and hides the previous one
     * if it wasn't hidden before a new one was opened.
     *
     * @param {HTMLElement} popup
     */
    function popupStateChanged(popup) {
        if (openedPopups.indexOf(popup) === -1) {
            openedPopups.push(popup);

            if (openedPopups.length === 2) {
                togglePopup(openedPopups[0]); // Hides previous popup.
            }
        }
        else openedPopups.shift();

        toggleBodyOverlay();
    }

    /**
     * Toggles body overlay when any popup is shown or hidden.
     */
    function toggleBodyOverlay() {
        if (openedPopups.length && !isBodyOverlayVisible) {
            $(bodyOverlay).fadeIn(100);
            body.classList.add('overlay');
        }
        else if (!openedPopups.length && isBodyOverlayVisible) {
            $(bodyOverlay).fadeOut(100);
            body.classList.remove('overlay');
        }

        isBodyOverlayVisible = !isBodyOverlayVisible;
    }
})();


/**
 * Clocks in the footer.
 */
(function() {
    // Adding necessary timezones to not load whole big file of data with all of them.
    // Latest data is in the node_modules/moment-timezone/data/packed/latest.json
    moment.tz.add([
        'Europe/London|GMT BST BDST|0 -10 -20|0101010101010101010101010101010101010101010101010121212121210101210101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-2axa0 Rc0 1fA0 14M0 1fc0 1g00 1co0 1dc0 1co0 1oo0 1400 1dc0 19A0 1io0 1io0 WM0 1o00 14o0 1o00 17c0 1io0 17c0 1fA0 1a00 1lc0 17c0 1io0 17c0 1fA0 1a00 1io0 17c0 1io0 17c0 1fA0 1cM0 1io0 17c0 1fA0 1a00 1io0 17c0 1io0 17c0 1fA0 1a00 1io0 1qM0 Dc0 2Rz0 Dc0 1zc0 Oo0 1zc0 Rc0 1wo0 17c0 1iM0 FA0 xB0 1fA0 1a00 14o0 bb0 LA0 xB0 Rc0 1wo0 11A0 1o00 17c0 1fA0 1a00 1fA0 1cM0 1fA0 1a00 17c0 1fA0 1a00 1io0 17c0 1lc0 17c0 1fA0 1a00 1io0 17c0 1io0 17c0 1fA0 1a00 1a00 1qM0 WM0 1qM0 11A0 1o00 WM0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1tA0 IM0 90o0 U00 1tA0 U00 1tA0 U00 1tA0 U00 1tA0 WM0 1qM0 WM0 1qM0 WM0 1tA0 U00 1tA0 U00 1tA0 11z0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1o00 14o0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|10e6',
        'America/New_York|EST EDT EWT EPT|50 40 40 40|01010101010101010101010101010101010101010101010102301010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-261t0 1nX0 11B0 1nX0 11B0 1qL0 1a10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 RB0 8x40 iv0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|21e6',
        "Europe/Paris|PMT WET WEST CEST CET WEMT|-9.l 0 -10 -20 -10 -20|0121212121212121212121212121212121212121212121212123434352543434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434|-2nco8.l cNb8.l HA0 19A0 1iM0 11c0 1oo0 Wo0 1rc0 QM0 1EM0 UM0 1u00 10o0 1io0 1wo0 Rc0 1a00 1fA0 1cM0 1cM0 1io0 17c0 1fA0 1a00 1io0 1a00 1io0 17c0 1fA0 1a00 1io0 17c0 1cM0 1cM0 1a00 1io0 1cM0 1cM0 1a00 1fA0 1io0 17c0 1cM0 1cM0 1a00 1fA0 1io0 1qM0 Df0 Ik0 5M30 WM0 1fA0 1cM0 Vx0 hB0 1aq0 16M0 1ekn0 1cL0 1fC0 1a00 1fA0 1cM0 1cM0 1cM0 1fA0 1a00 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|11e6",
        "Europe/Berlin|CET CEST CEMT|-10 -20 -30|01010101010101210101210101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-2aFe0 11d0 1iO0 11A0 1o00 11A0 Qrc0 6i00 WM0 1fA0 1cM0 1cM0 1cM0 kL0 Nc0 m10 WM0 1ao0 1cp0 dX0 jz0 Dd0 1io0 17c0 1fA0 1a00 1ehA0 1a00 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|41e5",
        "Asia/Tokyo|JST JDT|-90 -a0|010101010|-QJH0 QL0 1lB0 13X0 1zB0 NX0 1zB0 NX0|38e6",
        "Europe/Madrid|WET WEST WEMT CET CEST|0 -10 -20 -10 -20|010101010101010101210343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343|-25Td0 19B0 1cL0 1dd0 b1z0 18p0 3HX0 17d0 1fz0 1a10 1io0 1a00 1in0 17d0 iIn0 Hd0 1cL0 bb0 1200 2s20 14n0 5aL0 Mp0 1vz0 17d0 1in0 17d0 1in0 17d0 1in0 17d0 6hX0 11B0 XHX0 1a10 1fz0 1a10 19X0 1cN0 1fz0 1a10 1fC0 1cM0 1cM0 1cM0 1fA0 1a00 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|62e5",
        "Europe/Lisbon|LMT WET WEST WEMT CET CEST|A.J 0 -10 -20 -10 -20|012121212121212121212121212121212121212121212321232123212321212121212121212121212121212121212121214121212121212121212121212121212124545454212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-2ldXn.f aPWn.f Sp0 LX0 1vc0 Tc0 1uM0 SM0 1vc0 Tc0 1vc0 SM0 1vc0 6600 1co0 3E00 17c0 1fA0 1a00 1io0 1a00 1io0 17c0 3I00 17c0 1cM0 1cM0 3Fc0 1cM0 1a00 1fA0 1io0 17c0 1cM0 1cM0 1a00 1fA0 1io0 1qM0 Dc0 1tA0 1cM0 1dc0 1400 gL0 IM0 s10 U00 dX0 Rc0 pd0 Rc0 gL0 Oo0 pd0 Rc0 gL0 Oo0 pd0 14o0 1cM0 1cP0 1cM0 1cM0 1cM0 1cM0 1cM0 3Co0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 pvy0 1cM0 1cM0 1fA0 1cM0 1cM0 1cN0 1cL0 1cN0 1cM0 1cM0 1cM0 1cM0 1cN0 1cL0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|27e5"
    ]);

    let clocks = document.querySelectorAll('.clock[data-tz]');
    for (let clock of clocks) {
        updateClock(clock, clock.dataset.tz);

        // Sets clocks update at each 30 seconds.
        setInterval(function() {
            updateClock(clock, clock.dataset.tz);
        }, 30000);
    }

    function updateClock(clock, timeZone) {
        // @todo Set default timezone if no one provided
        let now = moment.tz(timeZone),
            minute = now.minutes() / 60 * 360,
            hour = (now.hours() + now.minutes() / 60) / 12 * 360;

        clock.querySelector('.hour').style.transform = 'rotate(' + hour + 'deg)';
        clock.querySelector('.minute').style.transform = 'rotate(' + minute + 'deg)';
    }
})();


/**
 * Autoplays the video referenced in URL hash on the Videos page.
 *
 * We have to use hasData() method that insures that everything is loaded.
 * Otherwise there will be some missing styles or markup in popups.
 */
(function() {
    // Run only on the videos page
/*    if (window.location.hash) {
        let hash = window.location.hash.substr(1);
        let videoContainer = document.getElementById(hash);
        videoContainer.firstChild.click();
    }*/

/*    if (window.location.hash) {
        Wistia.api('zcjhrmmyvs').hasData(function() {
            let hash = window.location.hash.substr(1);
            let videoContainer = document.getElementById(hash);
            videoContainer.firstChild.click();
        });
    }*/

    if (body.id === 'videos-page') {
        if (window.location.hash) {
            let hash = window.location.hash.substr(1);
            let videoContainer = document.getElementById(hash);
            let wistiaVideoId = videoContainer.classList.value.match(/wistia_async_([^\s]+)/);

            if (wistiaVideoId) {
                Wistia.api(wistiaVideoId[1]).hasData(function() {
                    videoContainer.firstChild.click();
                });
            }
        }
    }
})();


/**
 * Triggers the "Ask our Experts" and "Request a Quote" popup forms.
 */
(function() {
    const askOurExpertsForm = document.getElementById('aoe-form');
    const requestQuoteForm = document.getElementById('rq-form');

    $('#aoe-modal-form').on('show.bs.modal', function(event) {
        let triggerButton = event.relatedTarget;
        askOurExpertsForm.product.value = triggerButton.dataset.product;
    });

    $('#rq-modal-form').on('show.bs.modal', function(event) {
        let triggerButton = event.relatedTarget;
        requestQuoteForm.product.value = triggerButton.dataset.product;
       
            // const productField = this.querySelector('form input[name="product"]');
            // When the popup is opened for the second time, there won't be the input[name="product"] element anymore.
            // Without this additional check an error will be thrown.
            // if (productField) {
            //     const productFieldGroup = productField.closest('.form-group');
            //     productFieldGroup.parentNode.removeChild(productFieldGroup);
            // }
        
    });
})();


/**
 * Navigation highlighting.
 *
 * In our case the header and sidebar navigations are built outside of ExpressionEngine
 * and there is no default way to know which page we're on.
 *
 * Each template specifies which section it's in:
 *      document.currentSection = 'mixers';
 * The currentSection names match the elements IDs in the navigation (except prefixes).
 */
document.addEventListener('DOMContentLoaded', function() {
    const currentSection = document.currentSection || null;
    const headerNavigatinPrefix = 'hn-';
    const sidebarNavigatinPrefix = 'msn-';

    if (!currentSection) return;

    let headerNavigationItem = document.getElementById(headerNavigatinPrefix + currentSection);
    if (headerNavigationItem) {
        headerNavigationItem.classList.add('active');
    }

    let sidebarNavigationItem = document.getElementById(sidebarNavigatinPrefix + currentSection);
    if (sidebarNavigationItem) {
        if (sidebarNavigationItem.classList.contains('collapsed')) {
            let sectionTitle = sidebarNavigationItem.parentElement.getElementsByClassName('section-title')[0];
            let dropdown = document.getElementById(sidebarNavigationItem.getAttribute('href').slice(1));

            sidebarNavigationItem.classList.remove('collapsed');
            sectionTitle.classList.add('active');
            dropdown.classList.add('show');
        }
        else {
            sidebarNavigationItem.classList.add('active');
        }
    }
});


$(document).ready(function() {
    /**
     * Bootstrap 4 carousels and tabs.
     *
     * jQuery matchHeight is used to avoid content jumping when tabs or slides are changing.
     * It handles hidden elements that is the case with tabs.
     *
     * I'm using more specific selector (with [data-ride]) to target only Bootstrap 4 carousel,
     * bevause the .carousel class is "global" and used for other types of carousels.
     */
    $('.carousel[data-ride]').each(function() {
        $(this).find('.carousel-item').matchHeight(false);
    });


    /**
     * Secondary navigation animated Scrollspy.
     * The solution found here: https://stackoverflow.com/questions/14804941/how-to-add-smooth-scrolling-to-bootstraps-scroll-spy-function
     */
    if (body.hasAttribute('data-spy') && body.hasAttribute('data-target')) {
        let spyTarget = body.dataset.target;

        $(spyTarget).find('a[href^="#"].nav-link').on('click', function(event) {
            event.preventDefault();

            let hash = this.hash;

            if (window.location.hash === hash) return;

            let offset = body.dataset.offset ? body.dataset.offset: 0;

            $('html, body').animate({
                scrollTop: $(hash).offset().top - offset + 1
            }, 300, function() {
                // If there is an offset and you will use
                //      window.location.hash = hash;
                // the scroll position will jump after the animation. I'm using pushState() instead.

                return window.history.pushState(null, null, hash);
            });
        });
    }


    /**
     * Accordions
     */
    /* @todo: change the class name to highlight autoscrolling, not just "has accrordion". */
    if (body.classList.contains('has-accordion')) {
        let $hash = $(window.location.hash);
        let offset = body.dataset.offset ? body.dataset.offset: 0;

        $hash.on('shown.bs.collapse', function () {
            $('html, body').animate({
                scrollTop: $hash.offset().top - offset
            }, 300);
        });

        $hash.collapse('toggle');
    }
});
